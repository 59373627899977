import { useContext, useEffect, useMemo, useState } from 'react';

import Card from '@/components/Card';
import CreateRecurringRuleModal from '@/modals/CreateRecurringRuleModal';
import { DEFAULT_PAGE_SIZE } from '@/constants';
import { GlobalContext } from '../../../context';
import Input from '@/components/Inputs';
import { Link } from 'react-router-dom';
import LoadingOverlay from '@/components/LoadingOverlay';
import Table from '@/components/Table';
import { prepareTableConfig } from '../../../utils';
import { recurringRuleService } from '@/services';
import { useRouteMatch } from 'react-router';

const CONFIG = {
  name: 'RecurringRules',
  widgets: [],
};

let queryDebounceTimer = null;

const SuperAdminRecurringRules = () => {
  const {
    currentPlatform: { uid: platformId },
  } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { url } = useRouteMatch();

  const [recurring_rules, setRecurringRules] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [showAddRecurringRule, setShowAddRecurringRule] = useState(false);
  const [paginatedData, setPaginatedData] = useState();
  const memoizedTableHeaders = useMemo(() => tableHeaders, [tableHeaders]);
  const memoizedTableData = useMemo(() => tableData, [tableData]);

  const [search, setSearch] = useState('');

  useEffect(() => {
    let isUnmounted = false;

    if (!isUnmounted) {
      const { tHeaders, tData } = prepareTableConfig(
        paginatedData,
        `recurring_rules`,
      );

      tHeaders.push({
        Header: () => null,
        id: 'edit',
        // eslint-disable-next-line react/display-name
        Cell: ({
          row: {
            original: {
              rawData: { uid },
            },
          },
        }) => (
          <Link
            to={`${url}/${uid}`}
            className="font-semibold text-mmj-blue underline"
          >
            edit
          </Link>
        ),
      });

      setTableHeaders(tHeaders);
      setTableData(tData);
    }

    return () => {
      isUnmounted = true;
    };
  }, [paginatedData]);

  useEffect(() => {
    loadRecurringRules({
      name: search,
    });

    return () => {
      if (queryDebounceTimer) {
        clearTimeout(queryDebounceTimer);
      }
    };
  }, []);

  useEffect(() => {
    setPaginatedData(
      recurring_rules.slice(
        (currentPage - 1) * DEFAULT_PAGE_SIZE,
        currentPage * DEFAULT_PAGE_SIZE,
      ),
    );
  }, [currentPage, recurring_rules]);

  const loadRecurringRules = async (params) => {
    setLoading(true);
    try {
      const { data } = await recurringRuleService.getRecurringRules({
        ...params,
      });
      setPageCount(Math.ceil(data.length / DEFAULT_PAGE_SIZE));
      setRecurringRules(data);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const onPageChange = (page) => {
    setCurrentPage(page);
  };

  const handleAddRecurringRule = () => {
    setShowAddRecurringRule(true);
  };

  const closeAddRecurringRuleModal = () => {
    setShowAddRecurringRule(false);
  };

  const onSearchChanged = (e) => {
    setSearch(e.target.value);
    if (queryDebounceTimer) {
      clearTimeout(queryDebounceTimer);
    }

    queryDebounceTimer = setTimeout(() => {
      loadRecurringRules({
        name: e.target.value,
      });
    }, 300);
  };

  const onAddRecurringRule = async (recurring_ruleInfo) => {
    try {
      setLoading(true);
      closeAddRecurringRuleModal();
      const payload = { ...recurring_ruleInfo };

      await recurringRuleService.createRecurringRule(platformId, payload);
      loadRecurringRules({
        name: search,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Card>
      {loading && <LoadingOverlay show={loading} />}
      <div className="flex justify-between items-center mb-4">
        <div className="text-lg font-semibold">All {CONFIG.name}</div>
        <div className="flex space-x-2 items-center">
          <Input
            type="text"
            placeholder="Search..."
            onChange={onSearchChanged}
            id="payments-search"
            className="mb-0"
          />
          <button
            className="button button-primary"
            type="button"
            onClick={handleAddRecurringRule}
          >
            Add
          </button>
        </div>
      </div>
      <Table
        data={memoizedTableData}
        columns={memoizedTableHeaders}
        onPageChange={onPageChange}
        pageCount={pageCount}
        enablePagaination
      />
      <CreateRecurringRuleModal
        show={showAddRecurringRule}
        onClose={closeAddRecurringRuleModal}
        onSave={onAddRecurringRule}
      />
    </Card>
  );
};

export default SuperAdminRecurringRules;
