import {
  AdjustmentsIcon,
  CalendarIcon,
  CreditCardIcon,
  IdentificationIcon,
  MapIcon,
  TemplateIcon,
  UserGroupIcon,
  UsersIcon,
  MailIcon,
} from '@heroicons/react/outline';

import AddProviderView from '@/views/SuperAdmin/Providers/AddProvider';
import ConfigureProviderView from '@/views/SuperAdmin/Providers/ConfigureProvider';
import ConfigureStateView from '@/views/SuperAdmin/States/ConfigureState';
import ConfigureCouponView from '@/views/SuperAdmin/Coupons/ConfigureCoupon';
import ConfigureRecurringRuleView from '@/views/SuperAdmin/RecurringRules/ConfigureRecurringRule';
import DashView from '@/views/DashView';
import ProviderAppointments from '@/views/Provider/Appointments';
import ProviderCalendar from '@/views/Provider/Calendar';
// import ProviderPayments from '@/views/Provider/Payments';
import SettingsView from '@/views/SettingsView';
import SuperAdminAppointments from '@/views/SuperAdmin/Appointments';
import SuperAdminCalendar from '@/views/SuperAdmin/Calendar';
import SuperAdminCoupons from '@/views/SuperAdmin/Coupons';
import SuperAdminRecurringRules from '@/views/SuperAdmin/RecurringRules';
import SuperAdminDash from '@/views/SuperAdmin/Dashboard';
import SuperAdminPatients from '@/views/SuperAdmin/Patients';
import SuperAdminProviders from '@/views/SuperAdmin/Providers';
// import SuperAdminReports from '@/views/SuperAdmin/Reports';
import SuperAdminStates from '@/views/SuperAdmin/States';

const ROUTES = {
  portal_admin: [],
  super_admin: [
    {
      path: '/',
      label: 'Dashboard',
      icon: TemplateIcon,
      component: <SuperAdminDash />,
      showInSidebar: true,
    },
    {
      path: '/states',
      label: 'States',
      exact: true,
      icon: MapIcon,
      component: <SuperAdminStates />,
      showInSidebar: true,
    },
    {
      path: '/states/:stateId',
      label: 'Configure State',
      component: <ConfigureStateView />,
      showInSidebar: false,
    },
    {
      path: '/providers',
      label: 'Providers',
      exact: true,
      icon: IdentificationIcon,
      component: <SuperAdminProviders />,
      showInSidebar: true,
    },
    {
      path: '/add-provider',
      label: 'Add Provider',
      exact: true,
      component: <AddProviderView />,
      showInSidebar: false,
      options: { goBackTo: '/providers' },
    },
    {
      path: '/providers/:providerId',
      label: 'Edit Provider',
      component: <ConfigureProviderView />,
      showInSidebar: false,
      options: { goBackTo: '/providers' },
    },
    {
      path: '/coupons/:couponId',
      label: 'Configure coupon',
      component: <ConfigureCouponView />,
      showInSidebar: false,
      options: { goBackTo: '/coupons' },
    },
    {
      path: '/recurring_rules/:ruleId',
      label: 'Configure Recurring Rules',
      component: <ConfigureRecurringRuleView />,
      showInSidebar: false,
      options: { goBackTo: '/recurring_rules' },
    },
    {
      path: '/patients',
      label: 'Patients',
      icon: UserGroupIcon,
      component: <SuperAdminPatients />,
      showInSidebar: true,
    },
    {
      path: '/calendar',
      label: 'Calendar',
      icon: CalendarIcon,
      component: <SuperAdminCalendar />,
      showInSidebar: true,
    },
    {
      path: '/appointments',
      label: 'Appointments',
      icon: UsersIcon,
      component: <SuperAdminAppointments />,
      showInSidebar: true,
    },
    {
      path: '/coupons',
      label: 'Coupons',
      icon: CreditCardIcon,
      exact: true,
      component: <SuperAdminCoupons />,
      showInSidebar: true,
    },
    {
      path: '/recurring_rules',
      label: 'Recurring Rules',
      icon: MailIcon,
      exact: true,
      component: <SuperAdminRecurringRules />,
      showInSidebar: true,
    },
    {
      path: '/settings',
      label: 'Settings',
      icon: AdjustmentsIcon,
      component: <SettingsView />,
      showInSidebar: true,
    },
  ],
  provider: [
    {
      path: '/',
      label: 'Dashboard',
      icon: TemplateIcon,
      component: <DashView />,
      showInSidebar: true,
    },
    // {
    //   path: '/payments',
    //   label: 'Payments',
    //   icon: CreditCardIcon,
    //   component: <ProviderPayments />,
    //   showInSidebar: true,
    // },
    {
      path: '/calendar',
      label: 'Calendar',
      icon: CalendarIcon,
      component: <ProviderCalendar />,
      showInSidebar: true,
    },
    {
      path: '/appointments',
      label: 'Appointments',
      icon: UsersIcon,
      component: <ProviderAppointments />,
      showInSidebar: true,
    },
    {
      label: 'Settings',
      path: '/settings',
      icon: AdjustmentsIcon,
      component: <SettingsView />,
      showInSidebar: true,
    },
  ],
  patient: [
    {
      label: 'Dashboard',
      path: '/',
      icon: TemplateIcon,
      component: <DashView />,
      showInSidebar: true,
    },
    // {
    //   label: 'Referrals',
    //   path: '/referrals',
    //   icon: ChatAlt2Icon,
    //   showInSidebar: true,
    // },
    {
      label: 'Settings',
      path: '/settings',
      icon: AdjustmentsIcon,
      component: <SettingsView />,
      showInSidebar: true,
    },
  ],
};

export default ROUTES;
