export * from './validatePassword';

import moment from 'moment-timezone';

export const sample = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

export const humanizeStr = (str) => {
  let i;
  const frags = str.split('_');
  for (i = 0; i < frags.length; i += 1) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
};

export const prepareTableConfig = (data, entity, filter, headerConfig) => {
  // filter = {key:'etc',value:'etc'}
  if (data === undefined || data.length === 0)
    return { tHeaders: [], tData: [] };

  let tData = [];

  data.forEach((_item) => {
    const newItem = {};
    newItem.rawData = _item;

    Object.keys(_item).forEach((_key) => {
      // filter out keys that are arrays
      if (_item[_key] && Array.isArray(_item[_key])) return;
      // convert booleans to strings
      if (
        JSON.stringify(_item[_key]) === 'true' ||
        JSON.stringify(_item[_key]) === 'false'
      ) {
        newItem[_key] = JSON.stringify(_item[_key]);
      } else {
        if (
          entity === 'states' ||
          entity === 'providers' ||
          entity === 'patients'
        ) {
          if (_key === 'state') {
            newItem[_key] = _item.state_info ? _item.state_info.name : '';
          } else {
            newItem[_key] = _item[_key];
          }
        } else {
          newItem[_key] = _item[_key];
        }
      }
    });

    tData.push(newItem);
  });

  const tHeaders = [];
  const rawHeaders = Object.keys(tData[0]);
  const skipHeaders = [
    'uid',
    'created_at',
    'updated_at',
    'ip_address',
    'device_type',
    'timezone',
    'referral_source',
    'user_types',
    'platforms',
    'latest_terms_accepted',
    'platform_id',
    'state_custom_recommendation',
    'assessment_template_id',
    'rawData',
    'coupon_info',
    'recurring_rule_info',
    'state_info',
    'provider_fee',
    'paperwork_fee',
    'customer_id',
    'bank_account_id',
  ];

  rawHeaders.forEach((_rawHeader) => {
    if (skipHeaders.includes(_rawHeader)) return;
    if (headerConfig && !headerConfig.includes(_rawHeader)) return;

    tHeaders.push({
      Header: humanizeStr(_rawHeader),
      accessor: _rawHeader,
    });
  });

  if (filter) {
    tData = tData.filter((_item) => _item[filter.key] === filter.value);
  }

  return { tHeaders, tData };
};

export const buildQueryParams = (params) => {
  return Object.keys(params || {})
    .filter((key) => `${params[key]}`)
    .map((key) => [key, encodeURIComponent(`${params[key]}`)])
    .map((keyVal) => keyVal.join('='))
    .join('&');
};

export const voidFunction = () => {};

export const convertToTimezone = (datetime, timezone) => {
  if (timezone) {
    return moment.utc(datetime).tz(timezone);
  }

  return moment.utc(datetime).local();
};

export const convertDateTimeToDate = (datetime, timeZoneName) => {
  let m;
  if (timeZoneName) {
    m = moment.utc(datetime).tz(timeZoneName);
  } else {
    m = moment.utc(datetime).local();
  }
  return new Date(m.year(), m.month(), m.date(), m.hour(), m.minute(), 0);
};

export const getFifteenMinuteIntervals = (start_time, end_time) => {
  let intervals = [];
  let current = moment(start_time);

  while (current.isSameOrBefore(end_time)) {
    intervals.push(current.format('YYYY-MM-DD HH:mm:ss'));
    current.add(15, 'minutes');
  }

  return intervals;
};

export const getFullAddress = (account, allStates) => {
  const stateInfo = allStates.find((state) => state.code === account.state);

  const parts = [
    account.address1,
    account.address2,
    account.city,
    stateInfo ? stateInfo.name : '',
  ];

  let fullAddress = parts.filter((part) => part).join(', ');

  if (account.zip) {
    fullAddress += ` ${account.zip}`;
  }

  return fullAddress;
};
