import { ax } from './_ax';
import { buildQueryParams } from '@/utils';

const getRecurringRules = async (params) => {
  const res = await ax.get(`/recurring_rules?${buildQueryParams(params)}`);
  return res;
};

/**
 * Get a recurring_rule  by id
 * @param {string} uid - id for desired recurring_rule
 * @returns {object} desired recurring_rule object
 */
const getRecurringRuleById = async (uid) => {
  const { data } = await ax.get(`/recurring_rules/${uid}`);
  return data;
};

const updateRecurringRule = async (uid, payload) => {
  const { data } = await ax.patch(`/recurring_rules/${uid}`, payload);
  return data;
};

const createRecurringRule = async (platformId, recurring_ruleInfo) => {
  const payload = {
    platform_id: platformId,
    ...recurring_ruleInfo,
  };

  const { data } = await ax.post('/recurring_rules', payload);
  return data;
};

const getAllRecurringRules = async () => {
  const { data } = await ax.get('/recurring_rules');
  return data;
};

const deleteRecurringRule = (uid) => ax.delete(`/recurring_rules/${uid}`);

const recurringRuleService = {
  getRecurringRules,
  getRecurringRuleById,
  updateRecurringRule,
  createRecurringRule,
  deleteRecurringRule,
  getAllRecurringRules,
};
export default recurringRuleService;
