import { recurringRuleService } from '@/services';
import { useContext, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';

import Card from '@/components/Card';
import DeleteConfirmModal from '@/modals/DeleteConfirmModal';
import { GlobalContext } from '@/context';
import Input from '@/components/Inputs';
import RecurringRuleConfigContext from '@/views/SuperAdmin/RecurringRules/context';
import { ViewGridIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { useForm } from '@/hooks';

const SKIP_FIELDS = ['created_at', 'updated_at'];

const BasicInfoSection = () => {
  const {
    params: { ruleId },
  } = useRouteMatch();
  const history = useHistory();
  const { notify } = useContext(GlobalContext);
  const { currentRecurringRule, setLoading } = useContext(
    RecurringRuleConfigContext,
  );

  const [formValues, handleFormChange] = useForm({});
  const [showSaveButton, setShowSaveButton] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [errors, setErrors] = useState('');

  useEffect(() => {
    handleFormChange(currentRecurringRule, true);
  }, [currentRecurringRule]);

  const handleRowFormChange = (event) => {
    setShowSaveButton(true);
    handleFormChange(event);
  };
  const handleSave = async () => {
    setErrors('');
    if (!formValues.description) {
      setErrors('Description is required');
      return;
    }
    if (!formValues.template_id) {
      setErrors('Template ID is required');
      return;
    }
    if (!formValues.delay_in_days) {
      setErrors('Delay is required');
      return;
    }

    const newValues = {};
    Object.keys(formValues).forEach((_key) => {
      if (SKIP_FIELDS.includes(_key) || _key.startsWith('react-select')) {
        return;
      } else {
        newValues[_key] = formValues[_key];
      }
    });

    try {
      await recurringRuleService.updateRecurringRule(ruleId, newValues);
      notify({ type: 'success', message: 'Success!' });
      setShowSaveButton(false);
      history.push('/recurring_rules');
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }
  };

  const handleDelete = () => {
    setShowConfirm(true);
  };

  const onCloseDeleteConfirm = () => {
    setShowConfirm(false);
  };

  const onConfirmDelete = async () => {
    setShowConfirm(false);

    setLoading(true);

    try {
      await recurringRuleService.deleteRecurringRule(ruleId);
      history.push('/recurring_rules');
    } catch (err) {
      notify({ type: 'error', message: err.message });
    }
    setLoading(false);
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Edit RecurringRule
          </h3>
        </div>
        <div>
          <button
            type="button"
            className="button button-primary"
            onClick={handleSave}
            disabled={!showSaveButton}
          >
            Save
          </button>
          <button
            type="button"
            className="button button-danger ml-3"
            onClick={handleDelete}
          >
            Delete
          </button>
        </div>
      </div>
      {errors && (
        <div className="Login__errors__error p-4 mb-4 bg-red-50 text-red-900 font-semibold rounded-xl">
          {errors}
        </div>
      )}
      <div>
        <form onChange={handleRowFormChange}>
          <div className="w-full flex flex-col space-y-2">
            <div className="flex space-x-4">
              <div className="w-2/3">
                <Input
                  type="text"
                  label="Description"
                  id="description"
                  value={formValues?.description || ''}
                  onChange={() => {}}
                />
              </div>
            </div>
            <div className="flex space-x-4">
              <Input
                type="text"
                label="Template ID"
                id="template_id"
                value={formValues?.template_id || ''}
                onChange={() => {}}
              />
              <Input
                type="text"
                label="Delay (in days)"
                id="delay_in_days"
                value={formValues?.delay_in_days || ''}
                onChange={() => {}}
              />
            </div>
          </div>
        </form>
        <DeleteConfirmModal
          show={showConfirm}
          onCancel={onCloseDeleteConfirm}
          onConfirm={onConfirmDelete}
          title="Delete recurring_rule"
          description="Are you sure that you want to delete this recurring_rule?"
        />
      </div>
    </>
  );
};

const SECTIONS = [
  {
    label: 'Edit Recurring Rule',
    component: <BasicInfoSection />,
  },
];

const ConfigureRecurringRuleView = () => {
  let {
    params: { ruleId },
  } = useRouteMatch();

  const [loading, setLoading] = useState(true);
  const [currentSection, setCurrentSection] = useState(0);
  const [currentRecurringRule, setCurrentRecurringRule] = useState({});

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    setLoading(true);
    try {
      const { recurring_rule_info } =
        await recurringRuleService.getRecurringRuleById(ruleId);
      setCurrentRecurringRule(recurring_rule_info);
      setCurrentSection(0);
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  return (
    <RecurringRuleConfigContext.Provider
      value={{
        currentRecurringRule,
        setCurrentRecurringRule,
        loading,
        setLoading,
      }}
    >
      <div className="lg:grid lg:grid-cols-12 lg:space-x-5 lg:space-y-5">
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-3">
          <nav className="space-y-1">
            {SECTIONS.map((_section, index) => (
              <button
                key={_section.label}
                type="button"
                onClick={() => setCurrentSection(index)}
                className={classNames(
                  'w-full text-gray-900 hover:text-gray-900 hover:bg-gray-50 group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                  {
                    'bg-white text-mmj-blue hover:text-indigo-700 hover:bg-white':
                      currentSection == index,
                  },
                )}
              >
                {_section.label}
              </button>
            ))}
          </nav>
        </aside>
        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 ">
          <Card className="ConfigureStateView mb-4 relative">
            {loading && (
              <div className="absolute top-0 left-0 right-0 bottom-0 bg-white opacity-60 z-50 rounded flex justify-center items-center">
                <ViewGridIcon className="animate-spin h-6 w-6" />
              </div>
            )}
            {SECTIONS[currentSection] && SECTIONS[currentSection].component}
          </Card>
        </div>
      </div>
    </RecurringRuleConfigContext.Provider>
  );
};
export default ConfigureRecurringRuleView;
